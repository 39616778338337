<template>
  <div>
    <span v-if="phone" class="result-phone is-together">
      <span class="icon"><fa icon="phone" /></span>
      <span v-html="phone" />
    </span>
    <span v-if="record.website" class="result-website is-together">
      <span class="icon"><fa icon="globe" /></span>
      <span v-html="getUri" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    phone() {
      return this.record?.locations?.[0]?.phone ?? null;
    },
  },
  methods: {
    getUri() {
      let hostname;
      if (this.record.website.indexOf('//') > -1) {
        // eslint-disable-next-line prefer-destructuring
        hostname = this.website.split('/')[2];
      } else {
        // eslint-disable-next-line prefer-destructuring
        hostname = this.website.split('/')[0];
      }
      // eslint-disable-next-line prefer-destructuring
      hostname = hostname.split('?')[0];
      return hostname;
    },
  },
};
</script>
